import React from 'react';
import { Modal } from '../modal';

function HideAssignmentWarningModal({
  isUnitPageView = false,
  okCallback,
  updateDoNotShowPopupSetting
}) {
  return (
    <Modal
      shouldShowDoNotShowAgainContainer
      title={isUnitPageView
        ? 'Hide assignnment in a unit'
        : 'This assignment is currently assigned to a student or included in a unit'}
      onUpdateDoNotShowPopupSetting={() => updateDoNotShowPopupSetting('hideAssignmentModal')}
      okCallback={okCallback}
      okText="Hide Assignment" cancelText="Cancle">
      {isUnitPageView ?
        <p>
          This assignment will be hidden from teachers. Refereces to the assignment 
          will still appear in lessons.
        </p>
        : (
        <p>
          This assignment will be hidden from teachers and students.
          However, if it's part of a unit, refereces to the hidden assignment(s)
          will still appear in lessons.
        </p>
        )
      }
    </Modal>
  )
}

export default HideAssignmentWarningModal;